import { BrandDot } from '@/components/BrandDot'
import { SectionWrapper } from '@/components/SectionWrapper'
import { useTheme } from '@/store'
import { AboutTermDetailListQuery } from '@/types/__generated__/AboutTermDetailListQuery'
import { fluidFontSize } from '@apostrof/lib'
import { css } from '@emotion/core'
import { graphql, useStaticQuery } from 'gatsby'
import React, { Fragment } from 'react'

const query = graphql`
  query AboutTermDetailListQuery {
    contentfulPageAbout {
      termDetailHeading
      termDetailList {
        term
        detail {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
type Props = {
  id: string
}

export const AboutTermDetailList: React.FC<Props> = ({ id }) => {
  const theme = useTheme()

  const {
    contentfulPageAbout: { termDetailHeading, termDetailList },
  } = useStaticQuery<AboutTermDetailListQuery>(query)

  return (
    <SectionWrapper id={id}>
      <h2
        css={css`
          color: ${theme.color.text_default};
          ${fluidFontSize(30, 48, 320, theme.vwImageCapWidth)};
          text-align: center;
          letter-spacing: -0.03em;
        `}
      >
        {termDetailHeading}
        <BrandDot />
      </h2>

      <dl
        css={css`
          text-align: center;
          dt {
            margin-top: 4em;

            color: ${theme.color.text_muted};
            ${fluidFontSize(14, 18, 320, theme.vwImageCapWidth)};
            text-transform: uppercase;
            letter-spacing: 0.03em;
            font-weight: 700;
          }

          dd {
            margin-top: 0.5em;

            color: ${theme.color.text_light};
            ${fluidFontSize(24, 30, 320, theme.vwImageCapWidth)};

            ul {
              list-style: none;

              li + li {
                margin-top: 0.25em;
              }
            }
          }
        `}
      >
        {termDetailList.map(({ term, detail }) => (
          <Fragment key={term}>
            <dt>
              {term}
              <BrandDot />
            </dt>
            <dd
              dangerouslySetInnerHTML={{
                __html: detail.childMarkdownRemark.html,
              }}
            />
          </Fragment>
        ))}
      </dl>
    </SectionWrapper>
  )
}
