import { SectionWrapper } from '@/components/SectionWrapper'
import { useTheme } from '@/store'
import { AboutHeroQuery } from '@/types/__generated__/AboutHeroQuery'
import { css } from '@emotion/core'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

const query = graphql`
  query AboutHeroQuery {
    contentfulPageAbout {
      heroImage {
        fluid(maxWidth: 1024, quality: 95) {
          ...ContentfulFluid
        }
      }
    }
  }
`

type Props = {
  id: string
}

export const AboutHero: React.FC<Props> = ({ id }) => {
  const theme = useTheme()
  const {
    contentfulPageAbout: { heroImage },
  } = useStaticQuery<AboutHeroQuery>(query)

  return (
    <SectionWrapper id={id}>
      <Img
        fluid={heroImage.fluid}
        alt=""
        css={css`
          border-radius: ${theme.radius.photo};
          overflow: hidden;
          box-shadow: ${theme.elevation[2]};
        `}
      />
    </SectionWrapper>
  )
}
